import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { collection, doc, getDoc, onSnapshot, addDoc, serverTimestamp, query, orderBy, updateDoc, increment, writeBatch, getDocs } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import './PersonalMessage.css'; // Import CSS file for styling
import Header from './Header'; // Import the Header component

const PersonalMessage = () => {
  const { chatIndex } = useParams();
  const navigate = useNavigate();
  const [friend, setFriend] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false); // State to control the visibility of the pop-up
  const messagesEndRef = useRef(null);

  // Define the handleAcknowledge function using useCallback
  const handleAcknowledge = useCallback(async () => {
    try {
      const chatDocRef = doc(firestore, 'user_chats', chatIndex);
      const messagesCollection = collection(chatDocRef, 'messages');
      const q = query(messagesCollection, orderBy('createdAt', 'asc'));
      const querySnapshot = await getDocs(q); // Fetch the messages
      const batch = writeBatch(firestore);

      querySnapshot.forEach(doc => {
        const message = doc.data();
        if (message.to === auth.currentUser.uid && !message.seen) {
          batch.update(doc.ref, { seen: true }); // Mark messages as seen
        }
      });

      // Commit the batch
      await batch.commit();

      // Reset unseen count for the logged in user
      await updateDoc(chatDocRef, {
        [`metadata.unseenCount.${auth.currentUser.uid}`]: 0
      });

      // Hide the pop-up
      setShowPopup(false);

    } catch (error) {
      console.error("Error acknowledging messages: ", error);
    }
  }, [chatIndex]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setLoading(false);

        const fetchChatData = async () => {
          try {
            const chatDoc = doc(firestore, 'user_chats', chatIndex);
            const chatDocSnap = await getDoc(chatDoc);
            if (chatDocSnap.exists()) {
              const chatData = chatDocSnap.data();
              const participantUIDs = Object.keys(chatData.metadata.participants);
              const friendId = participantUIDs.find(uid => uid !== user.uid);

              if (friendId) {
                const friendDoc = doc(firestore, 'users', friendId);
                const friendDocSnap = await getDoc(friendDoc);
                if (friendDocSnap.exists()) {
                  const friendData = friendDocSnap.data();
                  setFriend(friendData);
                }
              }

              // Check unseenCount for the logged in user
              if (chatData.metadata.unseenCount && chatData.metadata.unseenCount[user.uid] > 0) {
                setShowPopup(true); // Show the pop-up if there are unseen messages
                handleAcknowledge(); // Automatically acknowledge the messages
              }

              const messagesCollection = collection(chatDoc, 'messages');
              const q = query(messagesCollection, orderBy('createdAt', 'asc'));
              const unsubscribeMessages = onSnapshot(q, (snapshot) => {
                const messagesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setMessages(messagesList);
                scrollToBottom(); // Scroll to bottom after loading messages
              });

              return () => {
                unsubscribeMessages();
              };
            }
          } catch (error) {
            console.error("Error fetching chat data: ", error);
          }
        };

        fetchChatData();

        // Set up an interval to run handleAcknowledge every 2 seconds
        const intervalId = setInterval(() => {
          handleAcknowledge();
        }, 2000);

        // Cleanup interval on unmount
        return () => {
          clearInterval(intervalId);
        };

      } else {
        navigate('/login');
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, [chatIndex, navigate, handleAcknowledge]); // Include handleAcknowledge in the dependencies

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "Sending...";
    
    const dateObj = new Date(timestamp.seconds * 1000);
    const timeString = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const dateString = dateObj.toLocaleDateString('en-GB').slice(0, 8); // Format the date as "31/03/24"
    
    return `${timeString} * ${dateString}`;
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (newMessage.trim() && auth.currentUser) {
      const chatDocRef = doc(firestore, 'user_chats', chatIndex);
      const messagesCollection = collection(chatDocRef, 'messages');
      const timestamp = serverTimestamp();
      
      // Add the new message with seen status set to false
      await addDoc(messagesCollection, {
        text: newMessage,
        createdAt: timestamp,
        from: auth.currentUser.uid,
        to: friend?.userUid || '',
        seen: false, // Ensure seen is set to false
      });

      // Increment the unseen count for the recipient and update the latest message date
      await updateDoc(chatDocRef, {
        [`metadata.unseenCount.${friend?.userUid}`]: increment(1),
        'metadata.latestMessage': timestamp, // Update the latest message timestamp
      });

      setNewMessage('');
      scrollToBottom(); // Scroll to bottom after sending a message
    }
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom(); // Scroll to bottom when component mounts
  }, [messages]); // Add this dependency to ensure scrolling when messages change

  if (loading || !friend) return <p>Loading...</p>;

  return (
    <div className="personal-message-page">
      <Header title={friend.username} /> {/* Display friend's username in the header */}
      <div className="personal-message-container">
        {showPopup && (
          <div className="popup" style={{ display: 'none' }}>
            <p>You have unread messages</p>
            <button className="send-button" onClick={handleAcknowledge}>Acknowledge</button>
          </div>
        )}
        <div className="messages-container">
          {messages.map((message, index) => {
            const formattedTimestamp = formatTimestamp(message.createdAt);
            return (
              <div
                key={index}
                className={`message ${message.from === auth.currentUser.uid ? 'sent' : 'received'}`}
              >
                <div className="message-content">
                  <p>{message.text}</p>
                  <small>{formattedTimestamp}</small>
                  {message.from === auth.currentUser.uid && (
                    <span className="indicator">{message.seen ? "✔" : "<"}</span>
                  )} {/* Show seen status */}
                </div>
              </div>
            );
          })}
          <div ref={messagesEndRef}></div> {/* This div ensures scrolling to bottom */}
        </div>
        <form onSubmit={handleSendMessage} className="send-message-form">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message"
            className="message-input"
          />
          <button
            type="submit"
            className="send-button"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default PersonalMessage;