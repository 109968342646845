import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, onSnapshot, query, where, doc, getDoc } from 'firebase/firestore';
import './Messages.css';
import logo from '../assets/logo.png'; // Import the logo
import defaultAvatar from '../assets/default-avatar.png'; // Import a default avatar

const Header = ({ title }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSignOut = () => {
    auth.signOut().then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  const isMessagesScreen = location.pathname === '/messages';

  return (
    <div className="header">
      {isMessagesScreen ? (
        <button className="back-button" onClick={handleSignOut}>Sign out</button>
      ) : (
        <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      )}
      <h1 className="header-title">{title}</h1>
      <img src={logo} alt="Logo" className="header-logo" onClick={() => navigate('/messages')} />
    </div>
  );
};

const Messages = () => {
  const [activeChats, setActiveChats] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login');
    } else {
      const user_chatsRef = collection(firestore, 'user_chats');
      const q = query(
        user_chatsRef,
        where(`metadata.participants.${auth.currentUser.uid}`, '!=', null)
      );

      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
  try {
    const activeChatsList = await Promise.all(
      querySnapshot.docs.map(async (chatDoc) => {
        const chatData = chatDoc.data();
        const friendIds = Object.keys(chatData.metadata.participants).filter(uid => uid !== auth.currentUser.uid);
        const isUnseen = chatData.metadata.unseenCount?.[auth.currentUser.uid] > 0;

        const participants = await Promise.all(friendIds.map(async friendId => {
          const friendDocRef = doc(firestore, 'users', friendId);
          const friendDocSnap = await getDoc(friendDocRef);
          const friendData = friendDocSnap.data();
          return { id: friendId, username: friendData.username, profilePicture: friendData.profile_picture };
        }));

        return { id: chatDoc.id, participants, isUnseen, latestMessage: chatData.metadata.latestMessage };
      })
    );

    // Filter out chats with invalid or missing latestMessage timestamps
    const validChatsList = activeChatsList.filter(chat => chat.latestMessage && chat.latestMessage.seconds);

    // Sort the list by the latest message timestamp
    validChatsList.sort((a, b) => b.latestMessage.seconds - a.latestMessage.seconds);

    setActiveChats(validChatsList);
  } catch (error) {
    console.error("Error fetching active chats: ", error);
  }
});

      // Cleanup the listener on component unmount
      return () => {
        unsubscribe();
      };
    }
  }, [navigate]);

  return (
    <div className="messages-page">
      <Header title="Chats" /> {/* Simplified Header component */}
      <div className="messages-container">
        <ul className="chat-list">
          {activeChats.map((chat, index) => (
            <li
              key={index}
              className={`chat-item ${chat.isUnseen ? 'unseen-chat' : ''}`} // Apply tint if unseen
              onClick={() => navigate(`/messages/${chat.id}`)}
            >
              {chat.participants.map((participant, i) => (
                <div key={i}>
                  <img
                    src={participant.profilePicture || defaultAvatar}
                    alt={`${participant.username}'s profile`}
                    className="chat-profile-pic"
                  />
                  <span className="chat-username">{participant.username}</span>
                  <span className="chat-timestamp">{new Date(chat.latestMessage?.seconds * 1000).toLocaleString()}</span>
                </div>
              ))}
            </li>
          ))}
        </ul>
        <button className="create-button" onClick={() => navigate('/friends')}>
          CREATE
        </button>
      </div>
    </div>
  );
};

export default Messages;