// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Messages from './components/Messages';
import PersonalMessage from './components/PersonalMessage';
import Friends from './components/Friends';

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/messages/:chatIndex" element={<PersonalMessage />} />
          <Route path="/friends" element={<Friends />} />
          <Route path="/" element={<Login />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;
