import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.png'; // Adjust path as needed

const Header = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className="header">
      <button className="back-button" onClick={() => navigate(-1)}>Back</button>
      <h1 className="header-title">{title}</h1>
      <img src={logo} alt="Logo" className="header-logo" onClick={() => navigate('/messages')} />
    </div>
  );
};

export default Header;