import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, getDoc, setDoc, doc } from 'firebase/firestore';
import './Friends.css'; // Import the CSS file
import defaultAvatar from '../assets/default-avatar.png'; // Import the default avatar
import Header from './Header'; // Ensure this is imported correctly

const Friends = () => {
  const [friends, setFriends] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/login');
    } else {
      const fetchFriends = async () => {
        try {
          const userRef = collection(firestore, 'users', auth.currentUser.uid, 'friends');
          const q = query(userRef, where('friends', '==', true));
          const querySnapshot = await getDocs(q);

          const friendsList = await Promise.all(
            querySnapshot.docs.map(async (friendDoc) => {
              const friendData = friendDoc.data();
              if (friendData.userRef) {
                const friendDocSnap = await getDoc(friendData.userRef); // Get the friend user document
                const data = friendDocSnap.data();
                if (data) {
                  return { id: friendDoc.id, username: data.username, profilePicture: data.profile_picture || defaultAvatar };
                }
              }
              return null; // Return null if no data is found
            })
          );

          setFriends(friendsList.filter(friend => friend !== null)); // Filter out null values
        } catch (error) {
          console.error("Error fetching friends: ", error);
        }
      };

      fetchFriends();
    }
  }, [navigate]);

  const handleFriendClick = async (friendId) => {
    try {
      // Check if a chat already exists between the two users
      const userChatsRef = collection(firestore, 'user_chats');
      const q = query(userChatsRef, where(`metadata.participants.${auth.currentUser.uid}`, '!=', null));
      const chatSnapshot = await getDocs(q);

      let chatId;
      chatSnapshot.forEach(doc => {
        if (doc.data().metadata.participants[friendId]) {
          chatId = doc.id;
        }
      });

      // If no chat exists, create a new one
      if (!chatId) {
        const newChatRef = doc(userChatsRef);
        const chatData = {
          metadata: {
            participants: {
              [auth.currentUser.uid]: true,
              [friendId]: true
            }
          }
        };
        await setDoc(newChatRef, chatData);
        chatId = newChatRef.id;
      }

      navigate(`/messages/${chatId}`);
    } catch (error) {
      console.error("Error handling friend click: ", error);
    }
  };

  return (
    <div className="friends-container">
      <Header title="Friends" /> {/* Use the Header component */}
      <ul className="friends-list">
        {friends.map((friend, index) => (
          <li key={index} className="friend-item" onClick={() => handleFriendClick(friend.id)}>
            <img src={friend.profilePicture || defaultAvatar} alt={`${friend.username}'s profile`} className="friend-profile-pic" />
            <span className="friend-username">{friend.username}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Friends;